export const POST_CATEGORY = {
  ALL: "All",
  FOLLOWING: "Following",
};

export const POST_COMMENT_DATE_RANGE = {
  TODAY: "Today",
  THIS_WEEK: "This Week",
  THIS_MONTH: "This Month",
  THIS_YEAR: "This Year",
  ALL_TIME: "All Time",
};
